import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import StickyMenu from "@containers/sticky-menu";
import PageHeader from "@containers/page-header/layout-01";
import IntroAreaOne from "@components/introarea/layout-4";
import IntroAreaOneReverse from "@components/introarea/layout-4";
import BoxSection from "@components/BoxSection/layout-one/layout-2";
import BoxSectionTwo from "@components/BoxSection/layout-three/layout-3";
import CaseStudy from "@containers/global/case-study/layout-01";
import { caseStudiesData } from "@constants";
import UseScroll from '@containers/scroll';
import QuoteForm from "@containers/translation-quote-form";
import CtaArea from "@containers/cta/layout-02";
import IntroArea from "@components/introarea/layout-1";
import IntroAreaTwo from "@components/introarea/layout-6";
import IntroAreaThree from "@components/introarea/layout-4";
import TopTier from "@containers/top-tier";
import ContactArea from "@containers/contact-us";
const Footer = lazy(() => import("@layout/footer/layout-01"))

export const query = graphql`
  query LocalizationConsultancySolutionPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "localization-consultancy" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;
const LocalizationConsultancySolutionPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page?.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 100) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Localization Consultancy Solutions"
        description=""
      />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile
        }}
      />
      {
        !isMobile && <StickyMenu data={content["sticky-menu-data"]} setShowRestComponents={setShowRestComponents} showRestComponents={showRestComponents} />
      }
      <main className="site-wrapper-reveal">
        <PageHeader data={content["localization-consultancy-page-header-section"]} />
        <QuoteForm />
        {
          (!isMobile || (isMobile && showRestComponents)) && <>
            <IntroAreaOne layout={4} data={content["consultancy-intro-body"]} />
          </>
        }
        {
          showRestComponents && <>
            <IntroAreaTwo layout={3} data={content["translation-quality-feature"]} />
            <BoxSectionTwo layout={5} data={content["advanced-ai-section"]} />
            <IntroAreaTwo layout={3} data={content["translation-quality-machine"]} />
            <IntroAreaThree layout={3} data={content["strategic-vendor-body"]} />
            <TopTier data={content["top-tier-body"]} />
            <IntroAreaOneReverse layout={2} data={content["streamlined-project-body"]} />
            <CtaArea data={content["cta-data"]} />
            <IntroArea layout={3} data={content["process-optimization-body"]} />
            <IntroAreaTwo layout={3} data={content["engineering-excellence"]} />
            <BoxSection layout={11} data={content["quality-checks-body"]} />
            <CaseStudy layout={3} data={caseStudiesData} />
            <ContactArea Layout={2} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

LocalizationConsultancySolutionPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default LocalizationConsultancySolutionPage;
